import React, { useState } from "react";
import Form from "react-bootstrap/Form";
// import LoaderButton from "react-bootstrap/Button";
import Popup from "../components/Popup";
import { Auth } from "aws-amplify";
import LoaderButton from "../components/LoaderButton";
import Carousel from "../components/FadeCarousel";
import { useAppContext } from "../libs/contextLib";
import { useHistory } from "react-router-dom";
import { onError } from "../libs/errorLib";
import { useFormFields } from "../libs/hooksLib";
// import Nav from "react-bootstrap/Nav";
// import { LinkContainer } from "react-router-bootstrap";
// import Button from "react-bootstrap/Button";

export default function Login() {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const { userHasAuthenticated } = useAppContext();
  const [changePassword, setChangePassword] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const CACloginText =
    "Visibility of SOFWERX Projects as well as the Tech Tuesday Schedule are limited to US Government Common Access Card (CAC) holders and DEFENSEWERX Employees only. CAC Users: To view SOFWERX protected sites, please insert your CAC and select the Sign in with CAC button below:";

  const togglePopup = () => {
    setIsOpen(!isOpen);
  };
  const CacLogin = async () => {
    try {
      //let response = await fetch('https://api.github.com/repos/javascript-tutorial/en.javascript.info/commits');
      let response = await fetch("https://cac.sofwerx.org/validate");
      // const body = await API.get(config.apiGateway.CAC_NAME, "/validate");
      // console.log("response = " + response)

      let body = await response.status;
      // console.log("body = " + body)

      let text = await response.json(); // read response body as json

      let responses = []; //array of promises
      responses.push(response);
      // console.log("responses = " + responses)

      let bodies = []; //array of promises
      bodies.push(body);
      Promise.all(responses).then(function () {
        // console.log("responses all"); // display here
        Promise.all(bodies).then(function () {
          // console.log("bodies all"); // display here
          body = bodies[0];
          // console.log( "text = " + body )
        });
      });
      try {
        // console.log("trying to use body.uname and body.pwd")
        await Auth.signIn(text.uname, text.pwd);
        // await Auth.signIn(response.uname, body.pwd);
        userHasAuthenticated(true);
      } catch (e) {
        onError(e);
      }
    } catch (err) {
      setIsOpen(!isOpen);
      console.log(err);
      // $loadingSpinner.classList.remove('loading');
      // $cacErrorClick.click();
    }
  };

  const [fields, handleFieldChange] = useFormFields({
    email: "",
    password: "",
  });

  const [passwordFields, handlePasswordFieldChange] = useFormFields({
    oldPassword: "",
    newPassword: "",
    confirmNewPassword: "",
  });

  function validateForm() {
    return fields.email.length > 0 && fields.password.trim().length > 0;
  }

  function validateNewPasswordForm() {
    return (
      passwordFields.oldPassword.trim().length > 0 &&
      passwordFields.newPassword.trim().length > 0 &&
      passwordFields.confirmNewPassword.trim().length > 0 &&
      passwordFields.oldPassword !== passwordFields.newPassword &&
      passwordFields.newPassword === passwordFields.confirmNewPassword
    );
  }
  async function confirmPassword(event) {
    event.preventDefault();
    setIsLoading(true);
    if (passwordFields.newPassword !== passwordFields.confirmNewPassword) {
      onError("Passwords do not match");
      return;
    } else if (passwordFields.newPassword === passwordFields.oldPassword) {
      onError("New password cannot be the same as old password");
      return;
    }

    try {
      await Auth.signIn(fields.email, fields.password.trim())
        .then((user) => {
          if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
            Auth.completeNewPassword(
              user, // the Cognito User Object
              passwordFields.newPassword // the new password
            )
              .then((user) => {
                // at this time the user is logged in if no MFA required
                userHasAuthenticated(true);
                setChangePassword(false);
                setIsLoading(false);
              })
              .catch((e) => {
                console.log(e);
                onError(e);
                setIsLoading(false);
              });
          } //else{}
        })
        .catch((e) => {
          console.log(e);
          onError(e);
        });
    } catch (e) {
      onError(e);
      setIsLoading(false);
    }
  }

  async function handleSubmit(event) {
    event.preventDefault();
    setIsLoading(true);
    if (!validateForm()) {
      onError("Fields cannot be empty");
      setIsLoading(false);
      return;
    }
    try {
      await Auth.signIn(fields.email, fields.password.trim())
        .then(async (user) => {
          // console.log(user.challengeName)
          if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
            // const { requiredAttributes } = user.challengeParam;
            // the array of required attributes, e.g ['email', 'phone_number']
            passwordFields.oldPassword = fields.password.trim();
            setChangePassword(true);
          } else {
            if (!user.challengeName) {
              //no challenge means user is in...
              userHasAuthenticated(true);
            } else {
              //something happened here
              console.log(user.challengeName);
            }
            // other situations
          }
          setIsLoading(false);
        })
        .catch((e) => {
          if (e.code === "PasswordResetRequiredException") {
            // console.log("Forgot password process goes here. ")
            //redirect to forgot password
            // onError("Please click on Forgot Password to reset password")
            history.push("/forgot-password");
          } else if (e.code === "UserNotFoundException") {
            console.log("User not found");
            onError(
              "No user associated with that email. Please click on Register to request an account, or sign in with Cac"
            );
          } else {
            console.log(e);
            onError(e);
          }
          setIsLoading(false);
        });
    } catch (e) {
      console.log(e);
      setIsLoading(false);
      if (!changePassword) {
        //Only display error if password change is not the issue
        onError(e);
      }
    }
  }

  function renderNewPasswordForm() {
    return (
      <Form onSubmit={confirmPassword}>
        <br />
        <h1>Please Reset Password</h1>
        <Form.Group controlId="oldPassword" size="lg">
          <Form.Label>Temp Password</Form.Label>
          <Form.Control
            type="password"
            value={passwordFields.oldPassword.trim()}
            onChange={handlePasswordFieldChange}
          />
        </Form.Group>
        <Form.Group controlId="newPassword" size="lg">
          <Form.Label>New Password</Form.Label>
          <Form.Control
            type="password"
            onChange={handlePasswordFieldChange}
            value={passwordFields.newPassword}
          />
        </Form.Group>
        <Form.Group controlId="confirmNewPassword" size="lg">
          <Form.Label>Confirm New Password</Form.Label>
          <Form.Control
            type="password"
            onChange={handlePasswordFieldChange}
            value={passwordFields.confirmNewPassword}
          />
        </Form.Group>
        <div className="btns">
          <input
            type="submit"
            isLoading={isLoading}
            disabled={!validateNewPasswordForm()}
            value="Verify"
          />
        </div>
      </Form>
    );
  }
  function renderLoginForm() {
    return (
      <div>
        <Form onSubmit={handleSubmit}>
          <div className="grid">
            <div className="grid-col grid-col_6of12">
              <Form.Group size="md" controlId="email">
                <Form.Label>Email</Form.Label>
                <Form.Control
                  autoFocus
                  type="text"
                  value={fields.email}
                  onChange={handleFieldChange}
                />
              </Form.Group>
            </div>
            <div className="grid-col grid-col_6of12">
              <Form.Group size="sm" controlId="password">
                <Form.Label>Password</Form.Label>
                <Form.Control
                  type="password"
                  value={fields.password.trim()}
                  onChange={handleFieldChange}
                />
              </Form.Group>
            </div>
          </div>
          <div className="btns">
            <input
              type="submit"
              isLoading={isLoading}
              disabled={!validateForm()}
              value="login"
            />
          </div>
        </Form>
        <div className="textBlock mix-textBlock_center">
          <div>
            <a href="/forgot-password">
              <span>Forgot Password?</span>
            </a>
          </div>
          <div>
            <a href="/membership-registration">
              <span>Register</span>
            </a>
          </div>
          {/* <p>{CACloginText}</p>
                        <div className="btns btns_med">
                            <button className="cacLogin" onClick={CacLogin}>Sign in With CAC</button>
                        </div> */}
        </div>
        <div>
          {isOpen && (
            <Popup
              content={
                <>
                  <b>CAC AUTHENTICATION FAILED</b>
                  <br />
                  <br />
                  <p>
                    We are sorry to see that it looks like your CAC credentials
                    did not go through correctly, some common issues with that
                    are:
                    <br />
                    <br /> 1. You may need to select a different certificate.{" "}
                    <br /> 2.You may need to update your in browser
                    certificates. <br /> 3.You may need to insert your CAC card.{" "}
                    <br />
                    <br />
                    Please make sure you have inserted your card and are using
                    the correct certificates then try to login again. <br />
                    <br />
                    If you do not have a CAC or the authentication still isnt
                    working please close this dialog and use our Login or
                    Register links on the page. <br />
                    <br />
                    Also if you feel you are having further issues please feel
                    free to contack us with ATTN: Website Support
                  </p>
                  <br />
                </>
              }
              handleClose={togglePopup}
            />
          )}
        </div>
      </div>
    );
  }

  return (
    <div className="Login">
      <Carousel />
      <div className="wrapper wrapper_midLimit">
        <div className="contentBlock">
          <div className="contentBlock-hd contentBlock-hd_wordpress">
            <h2 className="hdg hdg_2">User Content Area</h2>
          </div>
          <div className="contentBlock-bd contentBlock-bd_push">
            <p>
              This content is secured so that only approved members may view
              this content, if you have a user account please login below. If
              you do not have a user account yet please follow the{" "}
              <a href="/membership-registration">registration</a> form link
              below.
            </p>
            <p>
              <strong>NOTICE:</strong> We have recently updated our login
              services, if your password is not working please use the "Forgot
              Password" link and reset your password. Thank you for your
              patience and understanding.
            </p>
          </div>
        </div>
        {changePassword ? renderNewPasswordForm() : renderLoginForm()}
      </div>
    </div>
  );
}
